import React, { FC } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";

import { SEO } from "../components/seo";

import SecurityFundamentalsMain from "../components/SecurityFundamentalsUiComponents/SecurityFundamentalsMain";
import { ImageSeoUrlCheck } from "../components/Shared/ImageSeoUrlCheck/ImageSeoUrlCheck";
type Props = {
  data: any;
};

const SolutionTemplate: FC<Props> = ({ data }) => {
  return (
    <Layout location="/blog">
      <SEO
        title={data.sanitySolutions.title}
        description={data.sanitySolutions.description}
        type="website"
        image={ImageSeoUrlCheck(data.sanitySolutions._rawMainImage.asset.url)}
      />

      <SecurityFundamentalsMain data={data} />
    </Layout>
  );
};

export default SolutionTemplate;

export const query = graphql`
  query ($slug: String!) {
    sanitySolutions(id: { eq: $slug }) {
      _rawMainImage(resolveReferences: { maxDepth: 20 })
      _rawChallengeDescriptionRichText(resolveReferences: { maxDepth: 20 })
      _rawChallengeDescriptionRichTextDe(resolveReferences: { maxDepth: 20 })
      _rawYourSolutionDescriptionRichText(resolveReferences: { maxDepth: 20 })
      _rawYourSolutionDescriptionRichTextDe(resolveReferences: { maxDepth: 20 })
      calendyLink
      alt
      keyTopicTitle
      keyTopicTitleDe

      challengeDescriptionRichText {
        _rawChildren
        _type
        children {
          text
        }
      }
      challengeDescriptionRichTextDe {
        _rawChildren
        _type
        children {
          text
        }
      }
      yourSolutionDescriptionRichText {
        _rawChildren
        _type
        children {
          text
          _key
          _type
        }
      }
      yourSolutionDescriptionRichTextDe {
        _rawChildren
        _type
        children {
          text
        }
      }

      challengeTitle
      challengeTitleDe
      deliverablesFiveDescription
      deliverablesFiveTitle
      deliverablesFiveDescriptionDe
      deliverablesFiveTitleDe
      deliverablesFourDescriptionDe
      deliverablesFourDescription
      deliverablesFourTitleDe
      deliverablesTwoTitleDe
      deliverablesTwoTitle
      deliverablesTwoDescriptionDe
      deliverablesTwoDescription
      deliverablesThreeTitleDe
      deliverablesThreeTitle
      deliverablesThreeDescriptionDe
      deliverablesThreeDescription
      deliverablesSixTitleDe
      deliverablesSixTitle
      deliverablesSixDescriptionDe
      deliverablesSixDescription
      deliverablesOneTitleDe
      deliverablesOneTitle
      deliverablesOneDescriptionDe
      deliverablesOneDescription
      deliverablesFourTitle
      subtitle
      price
      subtitleDe
      title
      titleDe
      btnTextDe
      btnText
      yourSolutionAlt
      yourSolutionTitle
      yourSolutionTitleDe
      readyDescription
      readyDisclaimer
      readyDescriptionDe
      readyDisclaimerDe
      yourSolutionImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
        _rawAsset(resolveReferences: { maxDepth: 20 })
      }
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
        _rawAsset(resolveReferences: { maxDepth: 20 })
      }
      challengeImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
        _rawAsset(resolveReferences: { maxDepth: 20 })
      }
      readyImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
        _rawAsset(resolveReferences: { maxDepth: 20 })
      }
    }
  }
`;
