import React, { FC, useContext } from "react";

import { UseWindowSize } from "../hooks/UseWindowSize";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CustomButton from "../Shared/CustomButton/CustomButton";
import arrowRightWhite from "../../images/arrowRightWhite.svg";
import TwoColumnBanner from "../Shared/TwoColumnImgTextbanner/TwoColumnBanner";
// import KeyTopicsComponent from "../Shared/KeyTopicsComponent/KeyTopicsComponent";
import KeyTopicsComponent from "../Shared/KeyTopicsComponent/KeytopicsComponent";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import * as styles from "../SecurityFundamentalsUiComponents/SecurityFundamentalsComponents.module.scss";
import translate from "../../i18n/translate";
import { LanguageContext } from "../../context/languageContext";
import { PopupButton } from "react-calendly";

type Props = {
  data: any;
};

const SecurityFundamentalsMain: FC<Props> = ({ data }) => {
  const width = UseWindowSize();
  const { language } = useContext(LanguageContext);

  return (
    <div>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={
            language === "EN"
              ? data.sanitySolutions.title
              : data.sanitySolutions.titleDe
          }
          description={
            language === "EN"
              ? data.sanitySolutions.subtitle
              : data.sanitySolutions.subtitleDe
          }
          homeBackground={
            data.sanitySolutions.mainImage.asset.gatsbyImageData.images.fallback
              .src
          }
          date=""
          alt="Cloud Native"
          buttonText={
            language === "EN"
              ? data.sanitySolutions.btnText
              : data.sanitySolutions.btnTextDe
          }
          externalLink={data.sanitySolutions?.calendyLink}
        />
      ) : (
        <HeroBanner
          heading={
            language === "EN"
              ? data.sanitySolutions.title
              : data.sanitySolutions.titleDe
          }
          description={
            language === "EN"
              ? data.sanitySolutions.subtitle
              : data.sanitySolutions.subtitleDe
          }
          homeBackground={
            data.sanitySolutions.mainImage.asset.gatsbyImageData.images.fallback
              .src
          }
          date=""
          buttonText={
            language === "EN"
              ? data.sanitySolutions.btnText
              : data.sanitySolutions.btnTextDe
          }
          externalLink={data.sanitySolutions?.calendyLink}
        />
      )}
      <InnerWrapper>
        {data.sanitySolutions?.challengeTitle && (
          <div>
            <TwoColumnBanner
              data={{
                heading:
                  language === "EN"
                    ? data.sanitySolutions?.challengeTitle
                    : data.sanitySolutions?.challengeTitleDe,
                description:
                  language === "EN"
                    ? data.sanitySolutions?._rawChallengeDescriptionRichText
                    : data.sanitySolutions?._rawChallengeDescriptionRichTextDe,
                img: data.sanitySolutions?.challengeImage.asset.gatsbyImageData
                  .images.fallback.src,
                alt: data.sanitySolutions?.challengeTitle,
              }}
              headingTop={true}
            />
          </div>
        )}

        {data.sanitySolutions?.yourSolutionTitle && (
          <div>
            <TwoColumnBanner
              order1={data.sanitySolutions?.challengeTitle ? 2 : 1}
              order2={data.sanitySolutions?.challengeTitle ? 1 : 2}
              data={{
                heading:
                  language === "EN"
                    ? data.sanitySolutions?.yourSolutionTitle
                    : data.sanitySolutions?.yourSolutionTitleDe,
                description:
                  language === "EN"
                    ? data.sanitySolutions?._rawYourSolutionDescriptionRichText
                    : data.sanitySolutions
                        ?._rawYourSolutionDescriptionRichTextDe,
                img: data.sanitySolutions?.yourSolutionImage.asset
                  .gatsbyImageData.images.fallback.src,
                alt: data.sanitySolutions?.yourSolutionAlt,
              }}
              headingTop={true}
            />
          </div>
        )}

        <div className={styles.approach}>
          <KeyTopicsComponent
            keyTopicsTitle={
              language === "EN"
                ? data.sanitySolutions.keyTopicTitle
                : data.sanitySolutions.keyTopicTitleDe
            }
            KeytopicsData={[
              {
                count: "01",
                heading:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesOneTitle
                    : data.sanitySolutions.deliverablesOneTitleDe,
                description:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesOneDescription
                    : data.sanitySolutions.deliverablesOneDescriptionDe,
              },
              {
                count: "02",
                heading:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesTwoTitle
                    : data.sanitySolutions.deliverablesTwoTitleDe,
                description:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesTwoDescription
                    : data.sanitySolutions.deliverablesTwoDescriptionDe,
              },
              {
                count: "03",
                heading:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesThreeTitle
                    : data.sanitySolutions.deliverablesThreeTitleDe,
                description:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesThreeDescription
                    : data.sanitySolutions.deliverablesThreeDescriptionDe,
              },
              {
                count: "04",
                heading:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesFourTitle
                    : data.sanitySolutions.deliverablesFourTitleDe,
                description:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesFourDescription
                    : data.sanitySolutions.deliverablesFourDescriptionDe,
              },
              {
                count: "05",
                heading:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesFiveTitle
                    : data.sanitySolutions.deliverablesFiveTitleDe,
                description:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesFiveDescription
                    : data.sanitySolutions.deliverablesFiveDescriptionDe,
              },
              {
                count: "06",
                heading:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesSixTitle
                    : data.sanitySolutions.deliverablesSixTitleDe,
                description:
                  language === "EN"
                    ? data.sanitySolutions.deliverablesSixDescription
                    : data.sanitySolutions.deliverablesSixDescriptionDe,
              },
            ]}
          />
        </div>

        <div className={styles.knowUs}>
          <div className={styles.knowUsImage}>
            <div className={styles.knowUsSubDiv}>
              <img
                src={
                  data.sanitySolutions.readyImage.asset.gatsbyImageData.images
                    .fallback.src
                }
                alt=""
                className={styles.awsMainImage}
              />
            </div>
          </div>
          <div className={styles.knowUsText}>
            <h2 className="heading">
              <span>.</span>
              {translate("security-packages-buy-price-head")}
            </h2>
            <p>
              {data.sanitySolutions?.price !== "0" && (
                <>
                  <b>Price:</b> {data.sanitySolutions?.price} €
                  <b style={{ color: "#00ecbd" }}>*</b>
                  <br />
                  <br />
                </>
              )}
              {language === "EN"
                ? data.sanitySolutions?.readyDescription
                : data.sanitySolutions?.readyDescriptionDe}
            </p>
            {width.width < 1250 ? (
              <CustomButton
                text={
                  language === "EN"
                    ? data.sanitySolutions.btnText
                    : data.sanitySolutions.btnTextDe
                }
                image={arrowRightWhite}
                externalLink={data.sanitySolutions?.calendyLink}
                buttonClass={styles.contactButton}
              />
            ) : typeof window !== "undefined" ? (
              <PopupButton
                url={data.sanitySolutions?.calendyLink}
                rootElement={document.getElementById("___gatsby")}
                text={
                  language === "EN"
                    ? data.sanitySolutions.btnText
                    : data.sanitySolutions.btnTextDe
                }
                className={styles.contactButton}
                pageSettings={{
                  backgroundColor: "111111",
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: "00ecbd",
                  textColor: "ffffff",
                }}
              />
            ) : (
              <CustomButton
                text={
                  language === "EN"
                    ? data.sanitySolutions.btnText
                    : data.sanitySolutions.btnTextDe
                }
                image={arrowRightWhite}
                externalLink={data.sanitySolutions?.calendyLink}
                buttonClass={styles.contactButton}
              />
            )}
            <p style={{ fontSize: "11px" }}>
              <br />
              {data.sanitySolutions?.readyDisclaimer ||
              data.sanitySolutions?.readyDisclaimerDe ? (
                <b style={{ color: "#00ecbd" }}>*</b>
              ) : null}
              {language === "EN"
                ? data.sanitySolutions?.readyDisclaimer
                : data.sanitySolutions?.readyDisclaimerDe}
            </p>
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.15}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
          </div>
        </div>
      </InnerWrapper>
    </div>
  );
};

export default SecurityFundamentalsMain;
